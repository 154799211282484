<template>
  <div class="ac2" style="width: 100%">
    <!-- banner -->
    <div class="banner" style="width: 100%">
      <img src="/imgs/ct.jpg" style="width: 100%" />
    </div>
    <!-- 顶宽布局 -->
    <div class="container">
      <!-- 标题 -->
      <div class="a2-title">
        <h3>关于我们</h3>
        <div class="blink"></div>
      </div>
      <!-- 容器 -->
      <div class="a2-con">
        <div class="a2-l">
          <div class="a2-box">
            <p>
              北京贝塔科技有限公司成立于2011年10月24日，注册地位于北京市海淀区西小口路66号中关村东升科技园B-6号楼C座4层C402、403室;
            </p>
            <p>
              经营范围包括技术推广、技术服务、技术开发、技术转让、技术咨询；基础软件服务；应用软件服务；电脑动画设计；经济贸易咨询；设计、制作、代理、发布广告；组织文化艺术交流活动（不含营业性演出）；计算机系统服务；货物进出口、技术进出口、代理进出口;
            </p>

            <p>
              从事互联网文化活动。（市场主体依法自主选择经营项目，开展经营活动；从事互联网文化活动以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）北京贝塔科技有限公司对外投资10家公司，具有1处分支机构。
            </p>
          </div>
        </div>
        <div class="a2-r"></div>
      </div>
      <!-- 文本区 -->
      <div class="a2-info">
        <!-- 价值观 -->
        <div class="a2-item">
          <h3>联系我们:</h3>
          <p>公司名称：北京贝塔科技有限公司</p>
          <p>公司电话：010- 62927872</p>
          <!-- <p>公司邮箱：1215272580@qq.com</p> -->
          <p>网站域名：bettaer.com</p>
          <p>公司地址：北京市海淀区西小口路66号中关村东升科技园B6号楼C座3-4层/A座5层</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";

export default {
  name: "ac1",
  components: {},
  data() {
    return {};
  },
  mounted() {
    document.title = "贝塔互动";
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac2 {
  .banner {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    margin-top: 80px;
  }
  .container {
    // height: 1000px;
    margin: 60px auto;
    padding-top: 30px;
    box-sizing: border-box;
    // 标题
    .a2-title {
      text-align: center;
      h3 {
        font-size: 21px;
        color: #00bfb0;
      }
      .blink {
        width: 250px;
        height: 1px;
        background-color: #00bfb0;
        margin: 5px auto;
      }
    }

    // 容器
    .a2-con {
      width: 100%;
      height: 500px;
      //   background-color: pink;
      box-sizing: border-box;
      @include flex();
      // 左侧
      .a2-l {
        width: 50%;
        height: 100%;
        // background-color: red;
        position: relative;
        .a2-box {
          width: 80%;
          height: 80%;
          background-color: #4b80b7;
          position: absolute;
          right: 0;
          top: 50px;
          color: #fff;
          padding: 20px;
          box-sizing: border-box;
          p {
            font-size: 14px;
            margin: 25px auto;
          }
        }
      }
      // 右侧
      .a2-r {
        width: 50%;
        height: 100%;
        // background-color: yellow;
        background: url("/imgs/us.jpg") no-repeat center;
        background-size: cover;
      }
    }
    // 文本区
    .a2-info {
      .a2-item {
        margin-bottom: 60px;
        h3 {
          font-size: 24px;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          margin: 10px auto;
        }
      }
    }
  }
}
</style>
