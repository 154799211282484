<template>
  <div class="ac1">
    <!-- banner -->
    <div class="container">
      <img src="/imgs/info/1.png" alt="" style="margin-top: 30px" draggable="false" />
      <img src="/imgs/info/2.png" alt="" draggable="false"/>
      <img src="/imgs/info/3.png" alt="" draggable="false"/>
    </div>
  </div>
</template>

<script>
// import navigation from "./../components/navigation";
export default {
  data() {
    return {
      state: JSON.parse(window.localStorage.getItem("state")),
      href: "index",
      // 音乐列表
      songList: [],
      // 总页数
      pages: 2,
      // 当前页
      pageNo: 1,
      // 点击页数
      curPage: 1,
      // 根据页数获取数据
      my_data: [],
      drawer: false,

      showModal: false,
    };
  },
  components: {
    // navigation,
    // as,
    // Ma,
    // "a-player": VueAplayer,
  },
  mounted() {
    document.title = "贝塔互动";
    this.init();
  },
  methods: {
    // 获取音乐数据
    init() {
      this.axios.get("/mock/yp1.json").then((res) => {
        this.my_data = res.data;
      });
    },

    init2() {
      this.axios.get("/mock/yp2.json").then((res) => {
        this.my_data = res.data;
      });
    },

    // 分页器事件 //根据当前页获取数据
    msgListView(curPage) {
      this.curPage = curPage;
      // // console.log(this.curPage);
      // this.init();
      if (this.curPage == "1") {
        this.init();
      } else if (this.curPage == "2") {
        this.init2();
      } else if (this.curPage == "3") {
        this.init3();
      } else if (this.curPage == "4") {
        this.init4();
      } else if (this.curPage == "5") {
        this.init5();
      } else if (this.curPage == "6") {
        this.init6();
      }
    },

    // 收费逻辑
    gopay() {
      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("点击登录去购买~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound2/:" + id,
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  margin-top: 40px;
  .container {
    // width: 100%;
    img {
      cursor: pointer;
      width: 100%;
    }
  }
  // banner 层
  .banner {
    width: 100%;
    height: 700px;
    position: relative;
    // background: pink;
    img {
      width: 100%;
      height: 100%;
    }
    .container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // 容器
      .b-con {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @include flex();
        .b-img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .b-r {
          width: 600px;
          height: 300px;
          position: absolute;
          top: 15%;
          right: 8%;
          // background: #fff;
        }
      }
      .b-btn {
        width: 145px;
        height: 117px;
        position: absolute;
        top: 69%;
        right: 42%;
        display: flex;
        align-items: center;
        &:hover {
          .b-sm {
            opacity: 1;
          }
        }
        img {
          width: 100%;
          height: 45px;
          cursor: pointer;
        }

        .b-sm {
          width: 250px;
          height: 250px;
          margin-top: 30px;
          background: #fff;
          opacity: 1;
          margin-left: 60px;
        }
      }
    }
  }

  img {
    width: 100%;
  }

  .m-con {
    width: 100%;
    margin: 30px 0;
    img {
      width: 31.3%;
      height: auto;
      margin: 0 10px;
    }
  }
}
</style>
