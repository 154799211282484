<template>
  <div class="service">
    <div class="container">
      <!-- 标题 -->
      <div class="s-title">
        <h3>解决方案</h3>
        <h4>Solution</h4>
      </div>
      <!-- 内容区块 -->
      <div class="s-con">
        <!-- 循环项 -->
        <div class="s-list" @click="goSc()">
          <i class="iconfont icon-zhiboxianxing"></i>
          <span class="s-t">技术开发</span>
          <div class="blink"></div>
          <span>MORE + </span>
        </div>
        <!-- 循环项 -->
        <div class="s-list" @click="goSc()">
          <i class="iconfont icon-zhibo"></i>
          <span class="s-t">技术推广</span>
          <div class="blink"></div>
          <span>MORE + </span>
        </div>
        <!-- 循环项 -->
        <div class="s-list" @click="goSc()">
          <i class="iconfont icon-icon_guizu"></i>
          <span class="s-t">基础软件服务</span>
          <div class="blink"></div>
          <span>MORE + </span>
        </div>
        <!-- 循环项 -->
        <div class="s-list" @click="goSc()">
          <i class="iconfont icon-moban"></i>
          <span class="s-t">软件开发</span>
          <div class="blink"></div>
          <span>MORE + </span>
        </div>
        <!-- 循环项 -->
        <div class="s-list" @click="goSc()">
          <i class="iconfont icon-guanzhu"></i>
          <span class="s-t">技术服务</span>
          <div class="blink"></div>
          <span>MORE + </span>
        </div>
        <!-- 循环项 -->
        <div class="s-list" @click="goSc()">
          <i class="iconfont icon-zhuboguanli"></i>
          <span class="s-t">应用软件服务</span>
          <div class="blink"></div>
          <span>MORE + </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "service",
  methods: {
    // 关于我们跳转
    goUs() {
      this.$router.push("/ac2");
    },
    goSc() {
      // this.$router.push("/ac1");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.service {
  width: 100%;
  height: 700px;
  // background-color: #c60023;
  background: url("/imgs/fw.jpg") no-repeat center;
  background-size: cover;
  margin: 40px auto;
  .container {
    height: 100%;
    padding-top: 60px;
    box-sizing: border-box;
    // 标题
    .s-title {
      text-align: center;
      color: #fff;
      h3 {
        font-size: 28px;
      }
      h4 {
        margin: 15px auto;
        font-size: 16px;
      }
    }
    // 容器
    .s-con {
      width: 100%;
      height: 80%;
      //   background-color: #fff;
      // 循环项
      .s-list {
        width: 408px;
        height: 256px;
        background-color: transparent;
        float: left;
        text-align: center;
        border: 1px solid #fff;
        padding: 40px;
        box-sizing: border-box;
        &:hover {
          background-color: #c60023;
        }
        i {
          font-size: 60px;
          color: #fff;
          display: block;
        }
        .blink {
          width: 100px;
          height: 2px;
          background-color: #fff;
          margin: 30px auto;
        }
        span {
          font-size: 14px;
          color: #fff;
        }
        .s-t {
          color: #fff;
          font-size: 14px;
          margin-top: 30px;
          display: inline-block;
        }
      }
    }
  }
}
</style>
