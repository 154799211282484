<template>
  <div class="us">
    <!-- 右侧 介绍 -->
    <div class="us-info">
      <h2>关于我们</h2>
      <p>
      北京贝塔科技有限公司成立于2011年10月24日，注册地位于北京市海淀区西小口路66号中关村东升科技园B-6号楼C座4层C402、403室
      </p>
      <p>
     经营范围包括技术推广、技术服务、技术开发、技术转让、技术咨询；基础软件服务；应用软件服务；电脑动画设计；经济贸易咨询；设计、制作、代理、发布广告；组织文化艺术交流活动（不含营业性演出）；计算机系统服务；货物进出口、技术进出口、代理进出口;
      </p>
      <p>
        从事互联网文化活动。（市场主体依法自主选择经营项目，开展经营活动；从事互联网文化活动以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）北京贝塔科技有限公司对外投资10家公司，具有1处分支机构。
      </p>
      <div class="us-btn" @click="goUs()">
        <span> MORE ></span>
        <div class="blink"></div>
      </div>
    </div>

    <!-- 左侧图片 -->
    <div class="us-img">
      <img src="/imgs/xz.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 关于我们跳转
    goUs() {
      this.$router.push("/ac2");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.us {
  width: 100%;
  height: 400px;
  margin: 50px auto;
  // background: #c60023;
  @include flex();
  // 左侧图片
  .us-img {
    width: 50%;
    height: 100%;
    text-align: center;

    img {
      width: 400px;
      height: 400px;
      // margin: 30px auto;
    }
  }
  // 右侧 信息
  .us-info {
    width: 50%;
    height: 100%;
    // background-color: pink;
    padding-top: 30px;
    box-sizing: border-box;
    h2 {
      font-size: 31px;
    }

    p {
      font-size: 14px;
      margin: 20px auto;
    }

    .us-btn {
      width: 150px;
      text-align: center;
      height: 40px;
      //   background: #eee;
      position: relative;
      cursor: pointer;
      &:hover span {
        color: #fff;
      }
      &:hover .blink {
        height: 100%;
      }
      span {
        color: turquoise;
        position: absolute;
        left: 33%;
        bottom: 11px;
        z-index: 10;
        transition: all 0.2s;
      }
      .blink {
        width: 100%;
        height: 3px;
        background-color: turquoise;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: all 0.3s;
      }
    }
  }
}
</style>
