<template>
  <div class="nav-header">
    <div class="container">
      <!-- logo -->
      <div class="n-logo">
        <!-- <h4>北京智美理护信息技术有限公司</h4> -->
        <!-- <img src="/imgs/logo.png" alt="" /> -->
        <h2>贝塔互动</h2>
      </div>
      <!-- 右侧 -->
      <div class="n-r">
        <!-- 导航 -->
        <div class="n-list">
          <a href="/" class="active">首页</a>
          <a href="javascript:;" @click="goInfo()">行业资讯</a>
          <a href="javascript:;" @click="goSc()">监管措施</a>
          <a href="javascript:;" @click="goUs()">关于我们</a>
          <!-- <a href="javascript:;" @click="goCtm()">我的客户</a> -->
        </div>

        <div class="n-login" style="">
          <span class="n-login" @click="goLogin()" v-show="this.LoggedShow"> 登录 </span>
          <span class="n-login" @click="goRegister()" v-show="this.LoggedShow">
            注册
          </span>

          <!-- 用户名 -->
          <span class="n-info" v-show="!this.LoggedShow" v-text="this.nv_user.Username"
            ><i class="iconfont icon-denglu"></i
          ></span>

          <!-- 退出登录 -->
          <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
            <i class="iconfont icon-tuichudenglu"></i>
            退出登录
          </span>
        </div>
        <!-- 联系方式 -->
        <div class="n-phone">
          <!-- <i class="iconfont icon-weibiaoti-"></i>
          ****** -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav-header",
  props: {
    href: String,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
      k: this.$route.query.k,
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },
    // 关于我们跳转
    goUs() {
      this.$router.push("/ac2");
    },
    goSc() {
      this.$router.push("/ac1");
    },
    // 新闻跳转
    goInfo() {
      this.$router.push("/ac3");
    },
    goCtm() {
      this.$router.push("/ac4");
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      if (this.k) {
        window.localStorage.setItem("state", "10");
        window.localStorage.removeItem("k");
        this.$router.push("/");
        location.reload();
      } else {
        window.localStorage.setItem("state", "10");
        this.$router.push("/");
        location.reload();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.nav-header {
  width: 100%;
  height: 80px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  .container {
    height: 100%;
    @include flex();
    // border-bottom: 2px solid #000;
    // logo
    .n-logo {
      width: 320px;
      height: 100%;
      @include flex();
      // h4 {
      //   font-size: 21px;
      //   font-weight: 400;
      //   line-height: 50px;
      //   color: #00bfb0
      // }
      img {
        width: 70%;
        height: 100%;
      }
      h2 {
        font-size: 16px;
        color: #ff430b;
        cursor: pointer;
      }
    }
    // 右侧
    .n-r {
      @include flex();
      height: 100%;
      // 导航
      .n-list {
        margin-right: 80px;
        .active {
          color: #00bfb0;
        }
        a {
          font-size: 12px;
          color: #666;
          margin: 0 30px;
          transition: all 0.2s;

          &:hover {
            color: #00bfb0;
          }
        }
      }
      // 联系方式
      .n-phone {
        font-size: 14px;
        color: #666;
        width: 150px;
        // transition: all .3s;
        cursor: pointer;
        &:hover {
          font-weight: bold;
          font-size: 15px;
          color: #00bfb0;
        }
        i {
          font-size: 18px;
        }
      }

      // 登录注册
      .n-login {
        margin-right: 15px;
        span {
          font-size: 12px;
          color: #666;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
