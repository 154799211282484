<template>
  <div class="index">
    <!-- banner -->
    <banner></banner>
    <!-- 定宽布局 -->
    <div class="container">
      <!-- 关于我们 -->
      <us></us>
    </div>
    <!-- 服务项目 -->
    <service-items></service-items>
    <!-- 业务支持与合作方案 -->
    <!-- <business></business> -->

      <div class="list-con" style="display:none;">
      <img src="/imgs/1.png" alt="" />
      <img src="/imgs/2.png" alt="" />
      <img src="/imgs/3.png" alt="" />
    </div>


    <!-- 新闻区块 -->
    <journalism></journalism>

  
  </div>
</template>

<script>
import banner from "./../components/banner";
import us from "./../components/us";
import serviceItems from "./../components/Service";
// import business from "./../components/business";
import Journalism from "./../components/Journalism";
export default {
  name: "index",
  components: {
    banner,
    us,
    serviceItems,
    // business,
    Journalism,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "贝塔互动";
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  .container {
  }

  .list-con {
    // width: 100%;
    text-align: center;
    margin: 40px auto;
  }
}
</style>
