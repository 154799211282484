<template>
  <div class="ac1" style="width: 100%">
    <!-- banner -->
    <div class="banner" style="width: 100%">
      <img src="@/assets/imgs/ct.jpg" style="width: 100%" />
    </div>
    <!-- 顶宽布局 -->
    <div class="container">
      <!-- 标题 -->
      <div class="a1-title">
        <h3>我的客户</h3>
        <h4>My clients</h4>
      </div>
      <!-- 容器 -->
      <div class="a1-con">
        <!-- 轮播图 -->
        <div class="a1-swiper">
          <swiper :options="swiperOption">
            <!-- 循环想 -->
            <swiper-slide v-for="(item, index) in this.swiperList" :key="index">
              <img :src="item.img" alt="" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div> -->
          </swiper>
        </div>
        <!-- 轮播图 -->
        <div class="a1-swiper">
          <swiper :options="swiperOption">
            <!-- 循环想 -->
            <swiper-slide
              v-for="(item, index) in this.swiperList2"
              :key="index"
            >
              <img :src="item.img" alt="" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div> -->
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "ac1",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 轮播配置
      swiperOption: {
        autoplay: true,
        loop: true,
        // effect: "fade",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          img: "/imgs/kh/1.jpg",
        },
        {
          img: "/imgs/kh/2.jpg",
        },
      ],
      swiperList2: [
        {
          img: "/imgs/kh/4.jpg",
        },
        {
          img: "/imgs/kh/3.jpg",
        },
      ],
    };
  },
  mounted() {
    document.title = "极狐原生（北京）";
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  .banner {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    margin-top: 80px;
  }
  .container {
    height: 500px;
    // background-color: #456e45;
    margin: 60px auto;
    padding-top: 30px;
    box-sizing: border-box;
    // 标题
    .a1-title {
      text-align: center;
      h3 {
        font-size: 28px;
      }
      h4 {
        font-size: 18px;
        margin: 15px auto;
      }
    }
    // 容器
    .a1-con {
      width: 100%;
      height: 80%;
      //   background: #ff6600;
      @include flex();
      .a1-swiper {
        width: 588px;
        height: 324px;
        margin-top: 10px;
        .swiper-container {
          height: 100%;
          img {
            width: 600px;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
