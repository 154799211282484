<template>
  <div class="journalism">
    <div class="container">
      <!-- 标题 -->
      <div class="j-title">
        <h3>行业资讯</h3>
        <h4>Industry information</h4>
      </div>
      <!-- 容器 -->
      <div class="j-con">
        <!-- 循环项 -->
        <div class="j-item" v-for="(item, index) in this.my_data" :key="index">
          <!-- 时间 -->
          <div class="j-time">
            {{ item.pay }}
          </div>
          <!-- 标题 -->
          <div class="j-t">{{ item.title }}</div>
          <!-- 简介 -->
          <div class="j-jj">
            {{ item.jj }}
          </div>
          <!-- 按钮 -->
          <div class="j-btn" @click="goDays(item.methods, item.id)">查看详情 ></div>
        </div>
      </div>
    </div>
    <modal
      title="请付款阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <h2>微信支付：2元</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal.vue";
export default {
  data() {
    return {
      my_data: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  components: {
    Modal,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.axios.get("/mock/info.json").then((res) => {
        const data = res.data;
        this.my_data = data.slice(0, 18);
      });
    },
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    gopay() {
      // alert("资讯每篇2元，请付费阅读");
      this.showModal = true;
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.journalism {
  width: 100%;
  // height: 600px;
  background-color: rgba(241, 241, 241, 85%);
  .container {
    height: 100%;
    padding-top: 30px;
    box-sizing: border-box;
    // 标题
    .j-title {
      text-align: center;
      h3 {
        font-size: 28px;
      }
      h4 {
        font-size: 18px;
        margin: 20px auto;
      }
    }
    // 容器
    .j-con {
      width: 100%;
      height: 80%;
      //   background-color: pink;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      // 循环项
      .j-item {
        width: 385px;
        height: 310px;
        background-color: rgb(255, 255, 255);
        padding: 30px;
        box-sizing: border-box;
        transition: all 0.2s;
        &:hover {
          color: #fff;
          background-color: #c60023;
          transform: translateY(-15px);
        }
        &:hover .j-time {
          color: #fff;
        }
        &:hover .j-jj {
          color: #fff;
        }
        &:hover .j-btn {
          color: #fff;
        }
        // 时间
        .j-time {
          font-size: 14px;
          color: #7f8c8d;
        }
        // 标题
        .j-t {
          font-size: 18px;
          font-weight: bold;
          margin: 30px auto;
        }
        // 简介
        .j-jj {
          font-size: 12px;
          color: #7f8c8d;
          margin-bottom: 40px;
        }
        // 按钮
        .j-btn {
          font-size: 12px;
          color: #c60023;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
