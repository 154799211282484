<template>
  <!-- banner -->
  <div class="banner">
    <img src="/imgs/banner.jpg" alt="" />

    <div class="container" style="display:none;">
      <!-- 容器 -->
      <div class="b-con">
        <!-- 左侧图片 -->
        <div class="b-img animate__animated animate__bounceInUp">
          <img src="/imgs/n3.png" alt="" />
        </div>
        <div class="b-r animate__animated animate__bounceInDown">
          <img src="/imgs/下载.png" alt="" />
        </div>
      </div>

      <!-- 按钮区块 -->
      <div class="b-btn animate__animated animate__fadeInRight">
        <img src="/imgs/sm.png " alt="" />

        <img src="/imgs/xz.png" alt="" class="b-sm" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      swiperOption: {
        autoplay: true,
        loop: true,
        // effect: "coverflow",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/banner.jpg",
        },
        // {
        //   id: "2",
        //   img: "/imgs/1234.jpg",
        // },
      ],
    };
  },
  components: {
 
  },
  methods: {
    // 关于我们跳转
    goUs() {
      this.$router.push("/ac2");
    },
    goSc() {
      this.$router.push("/ac1");
    },
  },
};
</script>

<style>
</style>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
// banner 层
.banner {
  width: 100%;
  height: 600px;
  position: relative;
  margin-top: 80px;
  // background: pink;
  img {
    width: 100%;
    height: 100%;
  }
  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // 容器
    .b-con {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include flex();
      .b-img {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .b-r {
        width: 600px;
        height: 300px;
        position: absolute;
        top: 15%;
        right: 10%;
        // background: #fff;
      }
    }
    .b-btn {
      width: 145px;
      height: 117px;
      position: absolute;
      top: 53%;
      right: 30%;
      &:hover {
        .b-sm {
          opacity: 1;
        }
      }
      img {
        width: 100%;
        height: 45px;
        cursor: pointer;
      }

      .b-sm {
        width: 250px;
        height: 250px;
        margin-top: 30px;
        background: #fff;
        opacity: 0;
      }
    }
  }
}
</style>
